<template>
    <div>
        <router-view>

        </router-view>
    </div>
</template>

<script>
export default {
    name: 'RoutingSUHome',
    data() {
        return {

        }
    },
    methods:{

    }
}
</script>

<style>

</style>